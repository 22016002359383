import AjaxClass from './Validation/ajaxClass';
import FormValidate from './Validation/formValidateClass';
import MessageHandler from './Validation/messageHandler';
export default function formContact(){
    $("#contact-form").on('submit', function (e) {
        e.preventDefault();
        const Loader = document.getElementById("loading-forms")
        let form = $(this);
        let fields = form.serializeArray();
    
        let validation = new FormValidate(fields);
        const modalSuccess = document.getElementById('success-message-forms');
        const modalSuccessDescription = document.querySelector('#success-message-forms .description');
        const modalError = document.getElementById('error-message-forms');
        const modalDescription = document.querySelector('#error-message-forms .description');
    
        validation.validateAll(fields);
        let error = validation.hasError();
        
        MessageHandler.removeAllFormFailMessages()
        let terms = $("#checkbox").prop('checked') == true;
        if (!error && !terms){
            error = true
            const id = $("#checkbox").attr('idError')
            MessageHandler.showFailMessage(id, true);
            return false;
        }
    
        if (error) {
            let errorMessageId = validation.getErrorId();
            MessageHandler.showFailMessage(errorMessageId, false);
        }
    
        if (error == false) {
            Loader.classList.add("open-modal");
            if ($(this).attr('disabled') == 'disabled') {
                return false;
            }
            $(this).attr('disabled', 'disabled');
    
            let type = 'POST';
            let url = $(this).attr('action');
            let dataType = 'json';
    
            let formData = new FormData();
            fields.forEach(obj => {
                formData.append(obj.name, obj.value);
            });
    
            // Aqui inicia o AJAX
            $.ajax({
                type: type,
                url: url,
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                },
                data: formData, // Usando FormData diretamente
                dataType: dataType,
                processData: false, // Evita que o jQuery transforme a FormData em uma string
                contentType: false, // Não defina automaticamente o Content-Type
                beforeSend: function () {
                    // Loader.classList.add("open-modal"); // Removido, pois já foi adicionado anteriormente
                },
                success: function (data) {
                    if (data.status == true) {
                        form.trigger("reset"); // Resetar o formulário após o envio bem-sucedido
                        modalSuccessDescription.innerHTML = "Obrigado pela mensagem!";
                        modalSuccess.classList.add("open-modal");
                    } else {
                        modalDescription.innerHTML = data.txt;
                        modalError.classList.add("open-modal");
                    }
                },
                error: function(xhr, textStatus, errorThrown) {
                    modalDescription.innerHTML = "Ocorreu um erro. Tente novamente mais tarde.";
                    modalError.classList.add("open-modal");
                },
                complete: function () {
                    Loader.classList.remove("open-modal");
                    form.removeAttr('disabled');
                }
            });
        }
        return false;
    });    
}
